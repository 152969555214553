import useIPA from '@/auth/jwt/useJwt'
import router from '@/router/index.js'

// initial state
const state = () => ({
  parameter: {
    perPage: 20,
    pageOptions: [20, 50, 100],
    totalRows: 1,
    firstRow: 1,
    lastRow: 1,
    totalRows: 1,
    sortOptions: [],
    fields: [
      { key: 'name', label: 'Parameter', sortable: true },
      { key: 'value', label: 'Value', sortable: true },
      { key: 'unit', label: 'Satuan', sortable: true },
      { key: 'is_delete', label: 'Status', sortable: true },
      { key: 'action', label: 'Aksi'},
    ],
    items: [],
  },
  collation: {
    perPage: 20,
    pageOptions: [20, 50, 100],
    totalRows: 1,
    firstRow: 1,
    lastRow: 1,
    totalRows: 1,
    sortOptions: [],
    fields: [
      { key: 'name', label: 'Kelompok', sortable: true },
      { key: 'is_delete', label: 'Status', sortable: true },
      { key: 'action', label: 'Aksi'},
    ],
    items: [],
  },
  package: {
    perPage: 20,
    pageOptions: [20, 50, 100],
    totalRows: 1,
    firstRow: 1,
    lastRow: 1,
    totalRows: 1,
    sortOptions: [],
    fields: [
      { key: 'name', label: 'Kelompok', sortable: true },
      { key: 'is_delete', label: 'Status', sortable: true },
      { key: 'action', label: 'Aksi'},
    ],
    items: [],
  },
  options:{
    parameter:[],
    collation:[]
  }
})

// getters
const getters = {}

// actions
const actions = {
  getParam({ commit }, params) {
    return new Promise((resolve, reject) => {
      let param = {
        status: `finance/costing/service_class?type_costing_id=${params}`
        // status: `hr/costing/doctor_detail?health_facility_item_id=${params}`
      }
      useIPA.get(param).then((response) => {
        const Param = {
          rows: response.data.data
        }
        commit('setParamCosting', { ...Param })
      })
    })
  },
  getParameterAll ({ commit }) {
    let param = {status: 'health_facility/physiotherapy_parameter/detail'}
    useIPA.get(param).then(response => {
      const parameter = {
        rows : response.data.data
      }
      commit('setDataParameter', {...parameter})
    })
  },
  getCollationAll ({ commit }) {
    let param = {status: 'health_facility/physiotherapy_collation/detail'}
    useIPA.get(param).then(response => {
      const collation = {
        rows : response.data.data
      }
      commit('setDataCollation', {...collation})
    })
  },
  getPackageAll ({ commit }) {
    let param = {status: 'health_facility/physiotherapy_package/detail'}
    useIPA.get(param).then(response => {
      const packages = {
        rows : response.data.data
      }
      commit('setDataPackage', {...packages})
    })
  },
  getById ({ commit }, id) {
		return new Promise((resolve, reject) => {
      let param = {status: 'master/bank/' + id}
      useIPA.get(param).then(response => {
          resolve(response.data.data)
      })
    })
  },
  formPostPutParameter ({ commit }, formData) {
		return new Promise((resolve, reject) => {
      let url = 'health_facility/physiotherapy_parameter/create'
      if (formData.id) {
        url = 'health_facility/physiotherapy_parameter/update'
      }
      const param = { rawData: formData, url: url, id: formData.id }
      useIPA.postput(param)
			.then(response => {
        if (response.data.hasErrors) {
          reject(response.data.message)
        } else {
          resolve(response.data)
        }
      },
      error => {
        if (error.response.data.hasOwnProperty('hasErrors')) {
          reject(error.response.data.message)
        } else {
          reject(error.message)
        }
      })
		})
	},
  formPostPutCollation ({ commit }, formData) {
		return new Promise((resolve, reject) => {
      let url = 'health_facility/physiotherapy_collation/create'
      if (formData.id) {
        url = 'health_facility/physiotherapy_collation/update'
      }
      const param = { rawData: formData, url: url, id: formData.id }
      useIPA.postput(param)
			.then(response => {
        if (response.data.hasErrors) {
          reject(response.data.message)
        } else {
          resolve(response.data)
        }
      },
      error => {
        if (error.response.data.hasOwnProperty('hasErrors')) {
          reject(error.response.data.message)
        } else {
          reject(error.message)
        }
      })
		})
	},
  formPostPutPackage ({ commit }, formData) {
		return new Promise((resolve, reject) => {
      let url = 'health_facility/physiotherapy_package/create'
      if (formData.id) {
        url = 'health_facility/physiotherapy_package/update'
      }
      const param = { rawData: formData, url: url, id: formData.id }
      useIPA.postput(param)
			.then(response => {
        if (response.data.hasErrors) {
          reject(response.data.message)
        } else {
          resolve(response.data)
        }
      },
      error => {
        if (error.response.data.hasOwnProperty('hasErrors')) {
          reject(error.response.data.message)
        } else {
          reject(error.message)
        }
      })
		})
	},
  removeParameter ({ commit }, id) {
		return new Promise((resolve, reject) => {
      const rawData = {
        is_delete: 1,
			}
      const param = { rawData: rawData, url: 'health_facility/physiotherapy_parameter/delete', id: id }
      useIPA.delete(param)
			.then(response => {
        if (response.data.hasErrors) {
          reject(response.data.message)
        } else {
          resolve(response.data)
        }
      },
      error => {
        if (error.response.data.hasOwnProperty('hasErrors')) {
          reject(error.response.data.message)
        } else {
          reject(error.message)
        }
      })
		})
	},
  removeCollation ({ commit }, id) {
		return new Promise((resolve, reject) => {
      const rawData = {
        is_delete: 1,
			}
      const param = { rawData: rawData, url: 'health_facility/physiotherapy_collation/delete', id: id }
      useIPA.delete(param)
			.then(response => {
        if (response.data.hasErrors) {
          reject(response.data.message)
        } else {
          resolve(response.data)
        }
      },
      error => {
        if (error.response.data.hasOwnProperty('hasErrors')) {
          reject(error.response.data.message)
        } else {
          reject(error.message)
        }
      })
		})
	},
  removePackage ({ commit }, id) {
		return new Promise((resolve, reject) => {
      const rawData = {
        is_delete: 1,
			}
      const param = { rawData: rawData, url: 'health_facility/physiotherapy_package/delete', id: id }
      useIPA.delete(param)
			.then(response => {
        if (response.data.hasErrors) {
          reject(response.data.message)
        } else {
          resolve(response.data)
        }
      },
      error => {
        if (error.response.data.hasOwnProperty('hasErrors')) {
          reject(error.response.data.message)
        } else {
          reject(error.message)
        }
      })
		})
	}
}

// mutations
const mutations = {
  setDataParameter (state, parameter) {
    state.parameter.items = parameter.rows
    state.parameter.totalRows = parameter.rows.length
    state.options.parameter = []
    for (let i = 0; i < parameter.rows.length; i++) {
      const option = []
      for (const key in parameter.rows[i]) {
        if (key === 'id') { option['value'] = parameter.rows[i][key] 
        } else if (key === 'name') { option['title'] = parameter.rows[i][key] 
        } else if (key === 'value') { option['val'] = parameter.rows[i]['value'] 
        } else if (key === 'unit') { option['unit'] = parameter.rows[i][key] 
        } else if (key === 'id') { option['oid'] = parameter.rows[i][key] }
      }
      state.options.parameter.push(Object.assign({}, option))
    }
  },
  setDataCollation (state, collation) {
    state.collation.items = collation.rows
    state.collation.totalRows = collation.rows.length
    state.options.collation = []
    for (let i = 0; i < collation.rows.length; i++) {
      const option = []
      for (const key in collation.rows[i]) {
        if (key === 'id') { option['value'] = collation.rows[i][key] 
        } else if (key === 'name') { option['title'] = collation.rows[i][key] 
        } else if (key === 'parameter') { option['parameter'] = collation.rows[i][key] 
        } else if (key === 'id') { option['oid'] = collation.rows[i][key] }
      }
      state.options.collation.push(Object.assign({}, option))
    }
  },
  setDataPackage (state, packages) {
    state.package.items = packages.rows
    state.package.totalRows = packages.rows.length
  },
  setParamCosting (state, data) {
    state.options.costing = []
    for (let i = 0; i < data.rows.length; i++) {
      const option = []
      for (const key in data.rows[i]) {
        if (key === 'id') { option['value'] = data.rows[i][key] } else if (key === 'name') { option['title'] = data.rows[i][key] } else if (key === 'is_delete') { option['costing'] = 200000 } else if (key === 'id') { option['oid'] = data.rows[i][key] }
      }
      state.options.costing.push(Object.assign({}, option))
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
