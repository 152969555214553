export default [
    {
        path: '/queue/poli',
        name: 'QueuePoliView',
        component: () => import('@/views/pages/queue/poli/QueuePoliView.vue'),
        meta: {
            layout: 'full'
        }
    },
    {
        path: '/queue/nurse',
        name: 'QueueNurseView',
        component: () => import('@/views/pages/queue/nurse/QueueNurseView.vue'),
        meta: {
            layout: 'full'
        }
    },
    {
        path: '/queue/doctor',
        name: 'QueueDoctorView',
        component: () => import('@/views/pages/queue/doctor/QueueDoctorView.vue'),
        meta: {
            layout: 'full'
        }
    },
]
