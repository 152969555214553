import Vue from 'vue'
import Vuex from 'vuex'
import patient from './mr/patient'
import helper from './setting/helper'
import healthfacility from './setting/healthfacility'
import healthfacilityitem from './setting/healthfacilityitem'
import serviceclass from './setting/serviceclass'
import employee from './hr/employee'
import icd10 from './mr/icd10'
import icd9cm from './mr/icd9cm'
import position from './hr/position'
import registration from './mr/registration'
import howtoenter from './setting/howtoenter'
import catalog from './pharmacy/catalog'
import prescription from './pharmacy/prescription'
import depo from './pharmacy/depo'
import appointment from './mr/registration/appointment'
import nurse from './mr/nurse'
import soap from './mr/soap'
import costing from './finance/costing'
import payment from './tr/payment'
import laboratory from './health_facility/laboratory'
import radiology from './health_facility/radiology'
import physiotherapy from './health_facility/physiotherapy'
import doctorschedule from './medical_support/doctorschedule'
import company from './third_party/company'
import module from './setting/module'
import moduleitem from './setting/moduleitem'
import exit from './setting/exit'
import general from './general/general'
import timestamp from './setting/timestamp'
import brand from './third_party/brand'
import packaged from './setting/package'
import letter from './mr/letter'
import serviceactivity from './report/serviceactivity'
import dashboard from './dashboard/dashboard'
import doctorscheduleitem from './medical_support/doctorscheduleitem'
import room from './health_facility/room'
import packages from './setting/packages'
import signaturevalidation from './setting/signaturevalidation'
import planning from './pharmacy/planning'
import purchaseorder from './pharmacy/purchaseorder'
import paymentmethod from './finance/paymentmethod'
import receipt from './pharmacy/receipt'
import assets from './pharmacy/assets'
import tool from './mr/tool'
import blood from './mr/blood'
import bhp from './mr/bhp'
import cssd from './cssd/cssd'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    patient,
    helper,
    healthfacility,
    healthfacilityitem,
    serviceclass,
    employee,
    icd10,
    icd9cm,
    position,
    registration,
    howtoenter,
    catalog,
    prescription,
    depo,
    appointment,
    nurse,
    soap,
    costing,
    payment,
    laboratory,
    radiology,
    physiotherapy,
    company,
    module,
    moduleitem,
    exit,
    general,
    timestamp,
    doctorschedule,
    brand,
    packaged,
    letter,
    serviceactivity,
    dashboard,
    doctorscheduleitem,
    room,
    packages,
    brand,
    signaturevalidation,
    planning,
    purchaseorder,
    paymentmethod,
    receipt,
    assets,
    tool,
    blood,
    bhp,
    cssd,
  },
  strict: process.env.DEV,
})