export default [
    // GENERAL
    {
        path: '/prints/label/:id',
        name: 'PrintsLabelView',
        component: () => import('@/views/pages/all_prints/LabelView.vue'),
        meta: {
            layout: 'full'
        }
    },
    {
        path: '/prints/struk/:id',
        name: 'PrintsStrukView',
        component: () => import('@/views/pages/all_prints/StrukView.vue'),
        meta: {
            layout: 'full'
        }
    },
    {
        path: '/prints/card/:norm',
        name: 'PrintsCardView',
        component: () => import('@/views/pages/all_prints/CardView.vue'),
        meta: {
            layout: 'full'
        }
    },

    // BPJS
    {
        path: '/prints/bpjs-member/:id',
        name: 'PrintsBpjsMemberView',
        component: () => import('@/views/pages/all_prints/bpjs/BPJSMemberView.vue'),
        meta: {
            layout: 'full'
        }
    },
]
