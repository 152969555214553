export default [
    // Master Tarif
    {
        path: '/coding/main',
        name: 'CodingDiagnosaView',
        component: () => import('@/views/pages/coding/CodingDiagnosaView.vue'),
        meta: {
            layout: 'full'
        }
    },
    {
        path: '/coding/main/result',
        name: 'CodingResultView',
        component: () => import('@/views/pages/coding/CodingResultView.vue'),
        meta: {
            layout: 'full'
        }
    },

]
