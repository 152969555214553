import useIPA from '@/auth/jwt/useJwt'
import router from '@/router/index.js'

// initial state
const state = () => ({
  start: 20,
  totalRows: 1,
  fields: [
    { key: 'code', label: 'Kode', sortable: true },
    { key: 'name', label: 'Nama', sortable: true },
    { key: 'variable', label: 'Varibel', sortable: true },
    { key: 'description', label: 'Deskripsi', sortable: true },
    { key: 'is_delete', label: 'Status', sortable: true },
    { key: 'action', label: 'Aksi' },
  ],
  items: [],
  pageOptions: [20, 50, 100],
  sortOptions: [],
  perPage: 20,
  options: {
    alias: [],
    sex: [],
    religion: [],
    married: [],
    citizenship: [],
    blood: [],
    lastedu: [],
    profession: [],
    typeallergy: [],
    responsible: [],
    insurance: [],
    howtoenter: [],
    typecosting: [],
    province: [],
    regency: [],
    district: [],
    village: [],
    third_party_company: [],
    service_class: [],
    patient_issue: [],
    type_idcard: [],
    day: [],
    isNew: [],
    type_status_bed: [],
    type_room: [],
    typeRegister: [],
    typeEmergency: [],
    typeEmergencyCase: [],
    letter_type_id: [],
    typepurchaseorder:[],
    typeprice:[],
    typepaymentmethod:[]
  }
})

// getters
const getters = {}

// actions
const actions = {
  getParamLetterTypeId({ commit }, params) {
    return new Promise((resolve, reject) => {
      let param = {
        status: `setting/helper/detail?variable=${params}`
      }
      useIPA.get(param).then((response) => {
        const Param = {
          rows: response.data.data
        }
        commit('setParamLetterType', { ...Param })
      })
    })
  },

  getParamProvince({ commit }, params) {
    return new Promise((resolve, reject) => {
      let param = {
        status: `province/detail?flag_active=1`
      }
      useIPA.get(param).then((response) => {
        const Param = {
          rows: response.data.data
        }
        if (params === 'province') {
          commit('setParamProvince', { ...Param })
        }
      })
    })
  },
  getParamRegency({ commit }, params) {
    return new Promise((resolve, reject) => {
      let param = {
        status: `regency/detail?id_province=${params}`
      }
      useIPA.get(param).then((response) => {
        const Param = {
          rows: response.data.data
        }
        commit('setParamRegency', { ...Param })
      })
    })
  },
  getParamDistrict({ commit }, params) {
    return new Promise((resolve, reject) => {
      let param = {
        status: `district/detail?id_regency=${params}`
      }
      useIPA.get(param).then((response) => {
        const Param = {
          rows: response.data.data
        }
        commit('setParamDistrict', { ...Param })
      })
    })
  },
  getParamVillage({ commit }, params) {
    return new Promise((resolve, reject) => {
      let param = {
        status: `village/detail?id_district=${params}`
      }
      useIPA.get(param).then((response) => {
        const Param = {
          rows: response.data.data
        }
        commit('setParamVillage', { ...Param })
      })
    })
  },
  getParamThirdPartyCompany({ commit }, params) {
    return new Promise((resolve, reject) => {
      let param = {
        status: `third_party/company/detail?flag=1&is_delete=0`
      }
      useIPA.get(param).then((response) => {
        const Param = {
          rows: response.data.data
        }
        if (params === 'third_party_company') {
          commit('setParamThirdPartyCompany', { ...Param })
        }
      })
    })
  },
  getParamServiceClass({ commit }, params) {
    return new Promise((resolve, reject) => {
      let param = {
        status: `setting/service_class/detail?is_delete=0`
      }
      useIPA.get(param).then((response) => {
        const Param = {
          rows: response.data.data
        }
        if (params === 'service_class') {
          commit('setParamServiceClass', { ...Param })
        }
      })
    })
  },
  getParam({ commit }, params) {
    return new Promise((resolve, reject) => {
      let param = {
        status: `setting/helper/detail?variable=${params}`
      }
      useIPA.get(param).then((response) => {
        const Param = {
          rows: response.data.data
        }
        if (params === 'alias') {
          commit('setParamAlias', { ...Param })
        } else if (params === 'sex') {
          commit('setParamSex', { ...Param })
        } else if (params === 'religion') {
          commit('setParamReligion', { ...Param })
        } else if (params === 'married') {
          commit('setParamMarried', { ...Param })
        } else if (params === 'citizenship') {
          commit('setParamCitizenship', { ...Param })
        } else if (params === 'blood') {
          commit('setParamBlood', { ...Param })
        } else if (params === 'last_edu') {
          commit('setParamLastEdu', { ...Param })
        } else if (params === 'profession') {
          commit('setParamProfession', { ...Param })
        } else if (params === 'type_allergy') {
          commit('setParamTypeAllergy', { ...Param })
        } else if (params === 'responsible') {
          commit('setParamResponsible', { ...Param })
        } else if (params === 'insurance') {
          commit('setParamInsurance', { ...Param })
        } else if (params === 'type_how_to_enter') {
          commit('setParamHowToEnter', { ...Param })
        } else if (params === 'type_costing') {
          commit('setParamTypeCosting', { ...Param })
        } else if (params === 'patient_issue') {
          commit('setParamPatientIssue', { ...Param })
        } else if (params === 'typeidcard') {
          commit('setParamTypeIdcard', { ...Param })
        } else if (params === 'day') {
          commit('setParamDay', { ...Param })
        } else if (params === 'is_new') {
          commit('setParamIsNew', { ...Param })
        } else if (params === 'type_status_bed') {
          commit('setParamTypeStatusBed', { ...Param })
        } else if (params === 'type_room') {
          commit('setParamTypeRoom', { ...Param })
        } else if (params === 'type_register') {
          commit('setParamTypeRegister', { ...Param })
        } else if (params === 'type_emergency') {
          commit('setParamTypeEmergency', { ...Param })
        } else if (params === 'type_emergency_case') {
          commit('setParamTypeEmergencyCase', { ...Param })
        } else if (params === 'type_purchase_order') {
          commit('setParamTypePurchaseOrder', { ...Param })
        } else if (params === 'type_price') {
          commit('setParamTypePrice', { ...Param })
        } else if (params === 'type_payment_method') {
          commit('setParamTypePaymentMethod', { ...Param })
        }
      })
    })
  },
  getAll({ commit }, params) {
    let queryParams = [];

    if (params.offset) {
      queryParams.push(`offset=${params.offset}`);
    } else if (params.offset === 0) {
      queryParams.push(`offset=${params.offset}`);
    }

    if (params.limit) {
      queryParams.push(`limit=${params.limit}`);
    }

    if (params.code) {
      queryParams.push(`code=${params.code}`);
    }

    if (params.name) {
      queryParams.push(`name=${params.name}`);
    }

    if (params.variable) {
      queryParams.push(`variable=${params.variable}`);
    }

    let paramsall = queryParams.join('&');

    let param = { status: `setting/helper/list?${paramsall}` };

    useIPA.get(param).then(response => {
      const helper = {
        rows: response.data.data
      }
      commit('setData', { ...helper })
    })
  },
  // getAll ({ commit }) {
  //   let param = {status: 'setting/helper/detail'}
  //   useIPA.get(param).then(response => {
  //     const helper = {
  //       rows : response.data.data
  //     }
  //     commit('setData', {...helper})
  //   })
  // },
  getById({ commit }, id) {
    return new Promise((resolve, reject) => {
      let param = { status: 'master/bank/' + id }
      useIPA.get(param).then(response => {
        resolve(response.data.data)
      })
    })
  },
  formPostPut({ commit }, formData) {
    return new Promise((resolve, reject) => {
      const rawData = {
        type_sys: formData.product,
        type_branch: formData.type_product,
        code: formData.code,
        name: formData.name,
        phone: formData.phone,
        email: formData.email,
        password: formData.repassword,
      }
      const param = { rawData: rawData, url: 'initial/register'}
      useIPA.postput(param)
        .then(response => {
          router.push({ name: 'login' })
          if (response.data.hasErrors) {
            reject(response.data.message)
          } else {
            resolve(response.data)
          }
        },
          error => {
            if (error.response.data.hasOwnProperty('hasErrors')) {
              reject(error.response.data.message)
            } else {
              reject(error.message)
            }
          })
    })
  },
  formPostPutMaster({ commit }, formData) {
    return new Promise((resolve, reject) => {
      const rawData = {
        code: formData.code,
        name: formData.name,
        variable: formData.variable,
        description: formData.description,
      }
      let url = 'setting/helper/create'
      if (formData.id) {
        url = 'setting/helper/update'
      }
      const param = { rawData: rawData, url: url, id: formData.id }
      useIPA.postput(param)
        .then(response => {
          if (response.data.hasErrors) {
            reject(response.data.message)
          } else {
            resolve(response.data)
          }
        },
          error => {
            if (error.response.data.hasOwnProperty('hasErrors')) {
              reject(error.response.data.message)
            } else {
              reject(error.message)
            }
          })
    })
  },
  remove({ commit }, id) {
    return new Promise((resolve, reject) => {
      const rawData = {
        is_delete: 1,
      }
      const param = { rawData: rawData, url: 'setting/helper/delete', id: id }
      useIPA.delete(param)
        .then(response => {
          if (response.data.hasErrors) {
            reject(response.data.message)
          } else {
            resolve(response.data)
          }
        },
          error => {
            if (error.response.data.hasOwnProperty('hasErrors')) {
              reject(error.response.data.message)
            } else {
              reject(error.message)
            }
          })
    })
  }
}

// mutations
const mutations = {
  setData(state, helper) {
    state.items = helper.rows.rows
    state.totalRows = helper.rows.iTotal
    state.start = helper.rows.start
  },
  // setData (state, helper) {
  //   state.items = helper.rows
  //   state.totalRows = helper.rows.length
  // },
  setParamAlias(state, data) {
    state.options.alias = []
    for (let i = 0; i < data.rows.length; i++) {
      const option = []
      for (const key in data.rows[i]) {
        if (key === 'code') { option['value'] = data.rows[i][key] } else if (key === 'name') { option['title'] = data.rows[i][key] } else if (key === 'code') { option['oid'] = data.rows[i][key] }
      }
      state.options.alias.push(Object.assign({}, option))
    }
  },
  setParamSex(state, data) {
    state.options.sex = []
    for (let i = 0; i < data.rows.length; i++) {
      const option = []
      for (const key in data.rows[i]) {
        if (key === 'code') { option['value'] = data.rows[i][key] } else if (key === 'name') { option['title'] = data.rows[i][key] } else if (key === 'code') { option['oid'] = data.rows[i][key] }
      }
      state.options.sex.push(Object.assign({}, option))
    }
  },
  setParamMarried(state, data) {
    state.options.married = []
    for (let i = 0; i < data.rows.length; i++) {
      const option = []
      for (const key in data.rows[i]) {
        if (key === 'code') { option['value'] = data.rows[i][key] } else if (key === 'name') { option['title'] = data.rows[i][key] } else if (key === 'code') { option['oid'] = data.rows[i][key] }
      }
      state.options.married.push(Object.assign({}, option))
    }
  },
  setParamReligion(state, data) {
    state.options.religion = []
    for (let i = 0; i < data.rows.length; i++) {
      const option = [];
      for (const key in data.rows[i]) {
        if (key === 'code') { option['value'] = data.rows[i][key] } else if (key === 'name') { option['title'] = data.rows[i][key] } else if (key === 'code') { option['oid'] = data.rows[i][key] }
      }
      state.options.religion.push(Object.assign({}, option))
    }
  },
  setParamCitizenship(state, data) {
    state.options.citizenship = []
    for (let i = 0; i < data.rows.length; i++) {
      const option = [];
      for (const key in data.rows[i]) {
        if (key === 'code') { option['value'] = data.rows[i][key] } else if (key === 'name') { option['title'] = data.rows[i][key] } else if (key === 'code') { option['oid'] = data.rows[i][key] }
      }
      state.options.citizenship.push(Object.assign({}, option))
    }
  },
  setParamBlood(state, data) {
    state.options.blood = []
    for (let i = 0; i < data.rows.length; i++) {
      const option = [];
      for (const key in data.rows[i]) {
        if (key === 'code') { option['value'] = data.rows[i][key] } else if (key === 'name') { option['title'] = data.rows[i][key] } else if (key === 'code') { option['oid'] = data.rows[i][key] }
      }
      state.options.blood.push(Object.assign({}, option))
    }
  },
  setParamLastEdu(state, data) {
    state.options.lastedu = []
    for (let i = 0; i < data.rows.length; i++) {
      const option = [];
      for (const key in data.rows[i]) {
        if (key === 'code') { option['value'] = data.rows[i][key] } else if (key === 'name') { option['title'] = data.rows[i][key] } else if (key === 'code') { option['oid'] = data.rows[i][key] }
      }
      state.options.lastedu.push(Object.assign({}, option))
    }
  },
  setParamProfession(state, data) {
    state.options.profession = []
    for (let i = 0; i < data.rows.length; i++) {
      const option = [];
      for (const key in data.rows[i]) {
        if (key === 'code') { option['value'] = data.rows[i][key] } else if (key === 'name') { option['title'] = data.rows[i][key] } else if (key === 'code') { option['oid'] = data.rows[i][key] }
      }
      state.options.profession.push(Object.assign({}, option))
    }
  },
  setParamTypeAllergy(state, data) {
    state.options.typeallergy = []
    for (let i = 0; i < data.rows.length; i++) {
      const option = [];
      for (const key in data.rows[i]) {
        if (key === 'code') { option['value'] = data.rows[i][key] } else if (key === 'name') { option['title'] = data.rows[i][key] } else if (key === 'code') { option['oid'] = data.rows[i][key] }
      }
      state.options.typeallergy.push(Object.assign({}, option))
    }
  },
  setParamResponsible(state, data) {
    state.options.responsible = []
    for (let i = 0; i < data.rows.length; i++) {
      const option = [];
      for (const key in data.rows[i]) {
        if (key === 'code') { option['value'] = data.rows[i][key] } else if (key === 'name') { option['title'] = data.rows[i][key] } else if (key === 'code') { option['oid'] = data.rows[i][key] }
      }
      state.options.responsible.push(Object.assign({}, option))
    }
  },
  setParamInsurance(state, data) {
    state.options.insurance = []
    for (let i = 0; i < data.rows.length; i++) {
      const option = [];
      for (const key in data.rows[i]) {
        if (key === 'code') { option['value'] = data.rows[i][key] } else if (key === 'name') { option['title'] = data.rows[i][key] } else if (key === 'code') { option['oid'] = data.rows[i][key] }
      }
      state.options.insurance.push(Object.assign({}, option))
    }
  },
  setParamHowToEnter(state, data) {
    state.options.howtoenter = []
    for (let i = 0; i < data.rows.length; i++) {
      const option = [];
      for (const key in data.rows[i]) {
        if (key === 'code') { option['value'] = data.rows[i][key] } else if (key === 'name') { option['title'] = data.rows[i][key] } else if (key === 'code') { option['oid'] = data.rows[i][key] }
      }
      state.options.howtoenter.push(Object.assign({}, option))
    }
  },
  setParamTypeCosting(state, data) {
    state.options.typecosting = []
    for (let i = 0; i < data.rows.length; i++) {
      const option = [];
      for (const key in data.rows[i]) {
        if (key === 'code') { option['value'] = data.rows[i][key] } else if (key === 'name') { option['title'] = data.rows[i][key] } else if (key === 'code') { option['oid'] = data.rows[i][key] }
      }
      state.options.typecosting.push(Object.assign({}, option))
    }
  },
  setParamTypeIdcard(state, data) {
    state.options.type_idcard = []
    for (let i = 0; i < data.rows.length; i++) {
      const option = [];
      for (const key in data.rows[i]) {
        if (key === 'code') { option['value'] = data.rows[i][key] } else if (key === 'name') { option['title'] = data.rows[i][key] } else if (key === 'code') { option['oid'] = data.rows[i][key] }
      }
      state.options.type_idcard.push(Object.assign({}, option))
    }
  },
  setParamDay(state, data) {
    state.options.day = []
    for (let i = 0; i < data.rows.length; i++) {
      const option = [];
      for (const key in data.rows[i]) {
        if (key === 'code') { option['value'] = data.rows[i][key] } else if (key === 'name') { option['title'] = data.rows[i][key] } else if (key === 'code') { option['oid'] = data.rows[i][key] }
      }
      state.options.day.sort((a, b) => a.value - b.value).push(Object.assign({}, option))
    }
  },
  setParamIsNew(state, data) {
    state.options.isNew = []
    for (let i = 0; i < data.rows.length; i++) {
      const option = [];
      for (const key in data.rows[i]) {
        if (key === 'code') { option['value'] = data.rows[i][key] } else if (key === 'name') { option['title'] = data.rows[i][key] } else if (key === 'code') { option['oid'] = data.rows[i][key] }
      }
      state.options.isNew.push(Object.assign({}, option))
    }
  },
  setParamTypeRegister(state, data) {
    state.options.typeRegister = []
    for (let i = 0; i < data.rows.length; i++) {
      const option = [];
      for (const key in data.rows[i]) {
        if (key === 'code') { option['value'] = data.rows[i][key] } else if (key === 'name') { option['title'] = data.rows[i][key] } else if (key === 'code') { option['oid'] = data.rows[i][key] }
      }
      state.options.typeRegister.sort((a, b) => a.value - b.value).push(Object.assign({}, option))
    }
  },
  setParamTypeEmergency(state, data) {
    state.options.typeEmergency = []
    for (let i = 0; i < data.rows.length; i++) {
      const option = [];
      for (const key in data.rows[i]) {
        if (key === 'code') { option['value'] = data.rows[i][key] } else if (key === 'name') { option['title'] = data.rows[i][key] } else if (key === 'code') { option['oid'] = data.rows[i][key] }
      }
      state.options.typeEmergency.sort((a, b) => a.value - b.value).push(Object.assign({}, option))
    }
  },
  setParamTypeEmergencyCase(state, data) {
    state.options.typeEmergencyCase = []
    for (let i = 0; i < data.rows.length; i++) {
      const option = [];
      for (const key in data.rows[i]) {
        if (key === 'code') { option['value'] = data.rows[i][key] } else if (key === 'name') { option['title'] = data.rows[i][key] } else if (key === 'code') { option['oid'] = data.rows[i][key] }
      }
      state.options.typeEmergencyCase.sort((a, b) => a.value - b.value).push(Object.assign({}, option))
    }
  },
  setParamProvince(state, data) {
    state.options.province = []
    const option = []
    for (let i = 0; i < data.rows.length; i++) {
      for (const key in data.rows[i]) {
        if (key === 'id_province') { option['value'] = data.rows[i][key] } else if (key === 'nm_province') { option['title'] = data.rows[i][key] } else if (key === 'id_province') { option['oid'] = data.rows[i][key] }
      }
      state.options.province.push(Object.assign({}, option))
    }
  },
  setParamRegency(state, data) {
    state.options.regency = []
    const option = []
    for (let i = 0; i < data.rows.length; i++) {
      for (const key in data.rows[i]) {
        if (key === 'id_regency') { option['value'] = data.rows[i][key] } else if (key === 'nm_regency') { option['title'] = data.rows[i][key] } else if (key === 'id_regency') { option['oid'] = data.rows[i][key] }
      }
      state.options.regency.push(Object.assign({}, option))
    }
  },
  setParamLetterTypeId(state, data) {
    state.options.letter_type_id = []
    const option = []
    for (let i = 0; i < data.rows.length; i++) {
      for (const key in data.rows[i]) {
        if (key === 'letter_type_id') { option['value'] = data.rows[i][key] } else if (key === 'name') { option['title'] = data.rows[i][key] } else if (key === 'letter_type_id') { option['oid'] = data.rows[i][key] }
      }
      state.options.letter_type_id.push(Object.assign({}, option))
    }
  },
  setParamDistrict(state, data) {
    state.options.district = []
    const option = []
    for (let i = 0; i < data.rows.length; i++) {
      for (const key in data.rows[i]) {
        if (key === 'id_district') { option['value'] = data.rows[i][key] } else if (key === 'nm_district') { option['title'] = data.rows[i][key] } else if (key === 'id_district') { option['oid'] = data.rows[i][key] }
      }
      state.options.district.push(Object.assign({}, option))
    }
  },
  setParamVillage(state, data) {
    state.options.village = []
    const option = []
    for (let i = 0; i < data.rows.length; i++) {
      for (const key in data.rows[i]) {
        if (key === 'id_village') { option['value'] = data.rows[i][key] } else if (key === 'nm_village') { option['title'] = data.rows[i][key] } else if (key === 'id_village') { option['oid'] = data.rows[i][key] }
      }
      state.options.village.push(Object.assign({}, option))
    }
  },
  setParamThirdPartyCompany(state, data) {
    state.options.third_party_company = []
    const option = []
    for (let i = 0; i < data.rows.length; i++) {
      for (const key in data.rows[i]) {
        if (key === 'id') { option['value'] = data.rows[i][key] } else if (key === 'name') { option['title'] = data.rows[i][key] } else if (key === 'id') { option['oid'] = data.rows[i][key] }
      }
      state.options.third_party_company.push(Object.assign({}, option))
    }
  },
  setParamServiceClass(state, data) {
    state.options.service_class = []
    const option = []
    for (let i = 0; i < data.rows.length; i++) {
      for (const key in data.rows[i]) {
        if (key === 'id') { option['value'] = data.rows[i][key] } else if (key === 'name') { option['title'] = data.rows[i][key] } else if (key === 'id') { option['oid'] = data.rows[i][key] }
      }
      state.options.service_class.push(Object.assign({}, option))
    }
  },
  setParamPatientIssue(state, data) {
    state.options.patient_issue = []
    const option = []
    for (let i = 0; i < data.rows.length; i++) {
      for (const key in data.rows[i]) {
        if (key === 'code') { option['value'] = data.rows[i][key] } else if (key === 'name') { option['title'] = data.rows[i][key] } else if (key === 'code') { option['oid'] = data.rows[i][key] }
      }
      state.options.patient_issue.push(Object.assign({}, option))
    }
  },
  setParamTypeStatusBed(state, data) {
    state.options.type_status_bed = []
    const option = []
    for (let i = 0; i < data.rows.length; i++) {
      for (const key in data.rows[i]) {
        if (key === 'code') { option['value'] = data.rows[i][key] } else if (key === 'name') { option['title'] = data.rows[i][key] } else if (key === 'code') { option['oid'] = data.rows[i][key] }
      }
      state.options.type_status_bed.push(Object.assign({}, option))
    }
  },
  setParamTypeRoom(state, data) {
    state.options.type_room = []
    const option = []
    for (let i = 0; i < data.rows.length; i++) {
      for (const key in data.rows[i]) {
        if (key === 'code') { option['value'] = data.rows[i][key] } else if (key === 'name') { option['title'] = data.rows[i][key] } else if (key === 'code') { option['oid'] = data.rows[i][key] }
      }
      state.options.type_room.push(Object.assign({}, option))
    }
  },
  setParamTypePurchaseOrder (state, data) {
    state.options.typepurchaseorder = []
    const option = []
    for (let i = 0; i < data.rows.length; i++) {
      for (const key in data.rows[i]) {
        if (key === 'code') { option['value'] = data.rows[i][key] } else if (key === 'name') { option['title'] = data.rows[i][key] } else if (key === 'code') { option['oid'] = data.rows[i][key] }
      }
      state.options.typepurchaseorder.push(Object.assign({}, option))
    }
  },
  setParamTypePrice (state, data) {
    state.options.typeprice = []
    const option = []
    for (let i = 0; i < data.rows.length; i++) {
      for (const key in data.rows[i]) {
        if (key === 'code') { option['value'] = data.rows[i][key] } else if (key === 'name') { option['title'] = data.rows[i][key] } else if (key === 'code') { option['oid'] = data.rows[i][key] }
      }
      state.options.typeprice.push(Object.assign({}, option))
    }
  },
  setParamTypePaymentMethod (state, data) {
    state.options.typepaymentmethod = []
    const option = []
    for (let i = 0; i < data.rows.length; i++) {
      for (const key in data.rows[i]) {
        if (key === 'code') { option['value'] = data.rows[i][key] } else if (key === 'name') { option['title'] = data.rows[i][key] } else if (key === 'code') { option['oid'] = data.rows[i][key] }
      }
      state.options.typepaymentmethod.push(Object.assign({}, option))
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
