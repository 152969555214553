export default [
    // Master pemeriksaan eksternal
    {
        path: '/physiotherapy/external-checkup',
        name: 'PhysiotherapyExCheckupView',
        component: () => import('@/views/pages/physiotherapy/external_checkup/ExternalCheckupView.vue'),
        meta: {
            layout: 'full'
        }
    },

    // Permintaan pemeriksaan
    {
        path: '/physiotherapy/checkup-request',
        name: 'PhysiotherapyCheckupRequestView',
        component: () => import('@/views/pages/physiotherapy/checkup_request/CheckupRequestView.vue'),
        meta: {
            layout: 'full'
        }
    },
    {
        path: '/physiotherapy/checkup-request/process',
        name: 'PhysiotherapyCheckupRequestProcessView',
        component: () => import('@/views/pages/physiotherapy/checkup_request/PhysiotherapyProcessView.vue'),
        meta: {
            layout: 'full'
        }
    },

    // Laporan Pemeriksaan
    {
        path: '/physiotherapy/checkup-report',
        name: 'PhysiotherapyCheckupReportView',
        component: () => import('@/views/pages/physiotherapy/checkup_report/ReportCheckupView.vue'),
        meta: {
            layout: 'full'
        }
    },

]
