import useIPA from '@/auth/jwt/useJwt'
import router from '@/router/index.js'

const state = () => ({
    dashboardData: [],
});

const actions = {
    getDashboardByDate({ commit }, params) {
        return new Promise((resolve, reject) => {
            console.log(params)
            let queryParams = [];

            if (params) {
                if (params.date_start && params.date_end) {
                    queryParams.push(`date_start=${params.date_start}`)
                    queryParams.push(`date_end=${params.date_end}`)
                }
            }

            let paramsall = queryParams.join('&')
            let param = { status: `/dashboard/detail?${paramsall}` }

            useIPA.get(param)
                .then((response) => {
                    if (response.data) {
                        commit('setData', response.data.data);
                        resolve(response.data);
                    } else {
                        reject('Data tidak valid');
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        });
    },
};

const mutations = {
    setData(state, dashboard) {
        state.dashboardData = dashboard;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
}