export default [
    // ------------------------ Laboratory list -------------------------
    {
        path: '/laboratory/list',
        name: 'LaboratoryListView',
        component: () => import('@/views/pages/laboratory/LaboratoryListView.vue'),
        meta: {
            layout: 'full'
        }
    },
    {
        path: '/laboratory/list/process',
        name: 'LaboratoryListProcessView',
        component: () => import('@/views/pages/laboratory/LaboratoryListProcessView.vue'),
        meta: {
            layout: 'full'
        }
    },
    {
        path: '/laboratory/list/process/print-order',
        name: 'LaboratoryListProcessOrderView',
        component: () => import('@/views/pages/laboratory/print/OrderListView.vue'),
        meta: {
            layout: 'full'
        }
    },
    {
        path: '/laboratory/list/process/result',
        name: 'LaboratoryListProcessResultView',
        component: () => import('@/views/pages/laboratory/ProcessResultView.vue'),
        meta: {
            layout: 'full'
        }
    },
    {
        path: '/laboratory/list/process/result/print',
        name: 'LaboratoryListResultPrintView',
        component: () => import('@/views/pages/laboratory/print/ResultPrintView.vue'),
        meta: {
            layout: 'full'
        }
    },

    
    // ------------------------ Laboratory inspection -------------------------
    {
        path: '/laboratory/inspection',
        name: 'LaboratoryInspectionView',
        component: () => import('@/views/pages/laboratory/InspectionView.vue'),
        meta: {
            layout: 'full'
        }
    },
    {
        path: '/laboratory/inspection-report',
        name: 'LaboratoryInspectionReportView',
        component: () => import('@/views/pages/laboratory/InspectionReportView.vue'),
        meta: {
            layout: 'full'
        }
    },
    {
        path: '/laboratory/parameter',
        name: 'LaboratoryParameterView',
        component: () => import('@/views/pages/laboratory/LaboratoryParameterView.vue'),
        meta: {
            layout: 'full'
        }
    },
    {
        path: '/laboratory/collation',
        name: 'LaboratoryCollationView',
        component: () => import('@/views/pages/laboratory/LaboratoryCollationView.vue'),
        meta: {
            layout: 'full'
        }
    },
    {
        path: '/laboratory/action',
        name: 'LaboratoryActionView',
        component: () => import('@/views/pages/laboratory/LaboratoryActionView.vue'),
        meta: {
            layout: 'full'
        }
    },
    {
        path: '/laboratory/dashboard',
        name: 'LaboratoryDashboardView',
        component: () => import('@/views/pages/laboratory/LaboratoryDashboardView.vue'),
        meta: {
            layout: 'full'
        }
    },
]
