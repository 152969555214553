export default [
  // REGISTRATION 

  // List pendaftaran pasien
  {
    path: '/registration/patient-list',
    name: 'PatientListView',
    component: () => import('@/views/pages/registration/patient_registration_list/PatientRegistrationListView.vue'),
    meta: {
      layout: 'full'
    }
  },

  // List pendaftaran eksternal
  {
    path: '/registration/external-list',
    name: 'ExternalListView',
    component: () => import('@/views/pages/registration/external_registration_list/ExternalRegistrationListView.vue'),
    meta: {
      layout: 'full'
    }
  },

  // List pendaftaran batal
  {
    path: '/registration/cancel-list',
    name: 'CancelListView',
    component: () => import('@/views/pages/registration/cancel_registration_list/CancelRegistrationListView.vue'),
    meta: {
      layout: 'full'
    }
  },

  // List sensus rawat inap
  {
    path: '/registration/sensus-list',
    name: 'SensusListView',
    component: () => import('@/views/pages/registration/inpatient_census_list/InpatientCensusListView.vue'),
    meta: {
      layout: 'full'
    }
  },

  // Jadwal praktek dokter
  {
    path: '/registration/schedule-doctor',
    name: 'ScheduleDoctorView',
    component: () => import('@/views/pages/registration/doctor_practice_schedule/DoctorPracticeScheduleView.vue'),
    meta: {
      layout: 'full'
    }
  },

  // laporan kunjungan rawat jalan
  {
    path: '/registration/outpatient-visit-report',
    name: 'OutpatientVisitReportView',
    component: () => import('@/views/pages/registration/outpatient_visit_report/OutpatientVisitReportView.vue'),
    meta: {
      layout: 'full'
    }
  },

  // Pendaftaran
  {
    path: '/registration/regist-patient',
    name: 'RegisMasterView',
    component: () => import('@/views/pages/registration/registerPatient.vue'),
    meta: {
      layout: 'full'
    }
  },

  // Pendaftaran eksternal
  {
    path: '/registration/external',
    name: 'ExternalRegView',
    component: () => import('@/views/pages/registration/ExternalRegView.vue'),
    meta: {
      layout: 'full'
    }
  },
  
  // Laporan
  {
    path: '/registration/report',
    name: 'ReportView',
    component: () => import('@/views/pages/registration/Report.vue'),
    meta: {
      layout: 'full'
    }
  },
  
  // Dashboard
  //  #

  // History rekam medis
  {
    path: '/registration/medical-history',
    name: 'MedicalHistoryView',
    component: () => import('@/views/pages/registration/MedicalHistory.vue'),
    meta: {
      layout: 'full'
    }
  },

  // Merge rekam medis
  {
    path: '/registration/medical-record',
    name: 'MedicalRecordView',
    component: () => import('@/views/pages/registration/medical_record/MedicalRecordView.vue'),
    meta: {
      layout: 'full'
    }
  },

  // Laporan rekapitulasi kegiatan pelayanan
  {
    path: '/registration/service-report',
    name: 'ServiceReportView',
    component: () => import('@/views/pages/registration/service_report/ServiceReportView.vue'),
    meta: {
      layout: 'full'
    }
  },

  // CANVASING
  
  {
    path: '/canvasing',
    name: 'CanvasView',
    component: () => import('@/views/pages/canvasing/CanvasView.vue'),
    meta: {
      layout: 'full'
    }
  },

  // PATIENT STUDIES
  {
    path: "/patient/detail/:id",
    name: "PatientDetailView",
    component: () => import("@/views/pages/patient/detail/PatientDetailView.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/patient/cppt/:id",
    name: "PatientCPPTView",
    component: () => import("@/views/pages/patient/cppt/CPPTView.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/patient/nurse/:id",
    name: "NurseStudiesView",
    component: () => import("@/views/pages/patient/nurse/NurseStudiesView.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: '/patient/nurse/resume/:id',
    name: 'PrintsMrNurseView',
    component: () => import('@/views/pages/all_prints/medical_record/NurseMedicalRecord.vue'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: "/patient/doctor/:id",
    name: "DoctorStudiesView",
    component: () => import("@/views/pages/patient/doctor/DoctorStudiesView.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/patient/doctor/death-certified",
    name: "DeathCertificateView",
    component: () => import("@/views/pages/patient/doctor/DeathCertificateView.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/patient/doctor/health-certified",
    name: "HealthCertificateView",
    component: () => import("@/views/pages/patient/doctor/HealthCertificateView.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/patient/doctor/sick-certified",
    name: "SickCertificateView",
    component: () => import("@/views/pages/patient/doctor/SickCertificateView.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/patient/attribute",
    name: "PatientAttributeView",
    component: () => import("@/views/pages/patient/print_attribute/AttributeView.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/patient/laboratory/:id",
    name: "PatientLaboratoryView",
    component: () => import("@/views/pages/patient/laboratory/LaboratoryView.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/patient/laboratory/information-certificate",
    name: "PatientLaboratoryInfoCertificateView",
    component: () => import("@/views/pages/patient/laboratory/InformationCertificateView.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/patient/laboratory/order-certificate",
    name: "PatientLaboratoryOrderCertificateView",
    component: () => import("@/views/pages/patient/laboratory/OrderCertificateView.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/patient/radiology/:id",
    name: "PatientRadiologyView",
    component: () => import("@/views/pages/patient/radiology/RadiologyView.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/patient/radiology/order-certificate",
    name: "PatientRadiologyOrderCertificateView",
    component: () => import("@/views/pages/patient/radiology/OrderCertificateView.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/patient/procedure/:id",
    name: "PatientProcedureView",
    component: () => import("@/views/pages/patient/procedure/PatientProcedureView.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/patient/tool-use/:id",
    name: "PatientToolView",
    component: () => import("@/views/pages/patient/tool/ToolUseView.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/patient/blood-gourd/:id",
    name: "PatientBloodGourdView",
    component: () => import("@/views/pages/patient/blood_gourd/BloodGourdView.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/patient/bhp/:id",
    name: "PatientBHPView",
    component: () => import("@/views/pages/patient/BHP/BHPView.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/patient/OK/:id",
    name: "PatientOKView",
    component: () => import("@/views/pages/patient/OK/OKView.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/patient/MCU/:id",
    name: "PatientMCUView",
    component: () => import("@/views/pages/patient/MCU/MCUView.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/patient/nutrition/:id",
    name: "PatientNutritionView",
    component: () => import("@/views/pages/patient/nutrition/PatientNutritionView.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/patient/room/:id",
    name: "PatientRoomView",
    component: () => import("@/views/pages/patient/room/PatientRoomView.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/patient/alkes/:id",
    name: "PatientAlkesView",
    component: () => import("@/views/pages/patient/alkes/PatientAlkesView.vue"),
    meta: {
      layout: "full",
    },
  },
]
