import useIPA from '@/auth/jwt/useJwt'
import router from '@/router/index.js'

// initial state
const state = () => ({
  // perPage: 20,
  start: 20,
  pageOptions: [20, 50, 100],
  totalRows: 1,
  sortOptions: [],
  fields: [
    { key: 'norm', label: 'No RM', sortable: true },
    { key: 'name', label: 'Nama Pasien' },
    { key: 'sex_name', label: 'Jenis Kelamin' },
    { key: 'birth_place', label: 'Tempat Lahir' },
    { key: 'birth_date', label: 'Tanggal Lahir' },
    { key: 'address', label: 'Alamat' },
    { key: 'action', label: 'Aksi' }
  ],
  items: [],
  allergy: {
    perPage: 20,
    pageOptions: [
      { value: '20', text: '20' },
      { value: '50', text: '50' },
      { value: '100', text: '100' },
    ],
    totalRows: 1,
    sortOptions: [],
    fields: [
      { key: 'no', label: 'No' },
      { key: 'name', label: 'Alergi' },
      { key: 'type_allergy_id', label: 'Jenis Alergi' },
      { key: 'reaction', label: 'Reaksi' },
      { key: 'severity', label: 'Tingkat Keparahan' },
      { key: 'side_effect', label: 'Efek Samping' },
      { key: 'date_tragedy', label: 'Tgl Kejadian' }
    ],
    items: []
  },
  patientMaster: {
    fields: [
      { key: 'norm', label: 'No RM', thStyle: { minWidth: '140px' } },
      { key: 'name', label: 'Nama', thStyle: { minWidth: '140px' } },
      { key: 'birth_date', label: 'Tanggal Lahir', thStyle: { minWidth: '140px' } },
      { key: 'birth_place', label: 'Tempat Lahir', thStyle: { minWidth: '140px' } },
      { key: 'idcard', label: 'No Identitas', thStyle: { minWidth: '140px' } },
      { key: 'phone', label: 'No Telepon', thStyle: { minWidth: '140px' } },
      { key: 'address', label: 'Alamat', thStyle: { minWidth: '140px' } },
      { key: 'village_name', label: 'Kelurahan', thStyle: { minWidth: '140px' } },
      { key: 'district_name', label: 'Kecamatan', thStyle: { minWidth: '140px' } },
      { key: 'regency_name', label: 'Kodya', thStyle: { minWidth: '140px' } },
      { key: 'province_name', label: 'Propinsi', thStyle: { minWidth: '140px' } },
      { key: 'date_updated', label: 'Last Updated', thStyle: { minWidth: '140px' } },
      { key: 'action', label: 'Aksi', thStyle: { minWidth: '140px' } },
    ],
  },
})

// getters
const getters = {}

// actions
const actions = {
  getAll({ commit }, params) {
    let queryParams = [];

    if (params.offset) {
      queryParams.push(`offset=${params.offset}`);
    } else if (params.offset === 0) {
      queryParams.push(`offset=${params.offset}`);
    }

    if (params.limit) {
      queryParams.push(`limit=${params.limit}`);
    }

    if (params.norm) {
      queryParams.push(`norm=${params.norm}`);
    }

    if (params.idcard) {
      queryParams.push(`idcard=${params.idcard}`);
    }

    if (params.name) {
      queryParams.push(`name=${params.name}`);
    }

    if (params.birth_date) {
      queryParams.push(`birth_date=${params.birth_date}`);
    }
    if (params.birth_place) {
      queryParams.push(`birth_place=${params.birth_place}`);
    }
    if (params.address) {
      queryParams.push(`address=${params.address}`);
    }
    if (params.id_province) {
      queryParams.push(`province_id=${params.id_province.value}`);
    }
    if (params.regency_id) {
      queryParams.push(`regency_id=${params.regency_id.value}`);
    }
    if (params.district_id) {
      queryParams.push(`district_id=${params.district_id.value}`);
    }
    if (params.village_id) {
      queryParams.push(`village_id=${params.village_id.value}`);
    }
    if (params.zip_code) {
      queryParams.push(`zip_code=${params.zip_code}`);
    }

    let paramsall = queryParams.join('&');

    let param = { status: `mr/patient/list?${paramsall}` };
    useIPA.get(param).then(response => {
      const patient = {
        rows: response.data.data
      }
      commit('setListData', { ...patient })
    })
  },
  getDetail({ commit }) {
    let param = { status: 'mr/patient/detail' }
    useIPA.get(param).then(response => {
      const patient = {
        rows: response.data.data
      }
      commit('setData', { ...patient })
    })
  },
  getAllergyByNorm({ commit }, id) {
    let param = { status: 'mr/patient_allergy/detail/' + id }
    useIPA.get(param).then(response => {
      const allergy = {
        rows: response.data.data
      }
      commit('setDataAllergy', { ...allergy })
    })
  },
  getById({ commit }, id) {
    return new Promise((resolve, reject) => {
      let param = { status: 'master/bank/' + id }
      useIPA.get(param).then(response => {
        resolve(response.data.data)
      })
    })
  },
  bpjs({ commit }, params) {
    // console.log(norm);
    return new Promise((resolve, reject) => {
      let param = { status: `mr/patient/${params.url}/${params.idx}` }
      useIPA.get(param).then(response => {
        resolve(response.data.data)
      })
    })
  },
  getByNorm({ commit }, norm) {
    // console.log(norm);
    return new Promise((resolve, reject) => {
      let param = { status: 'mr/patient/detail/' + norm }
      useIPA.get(param).then(response => {
        resolve(response.data.data)
      })
    })
  },
  formPostPut({ commit }, formData) {
    return new Promise((resolve, reject) => {
      console.log('formData: ', formData);
      const rawData = {
        // institute_branch_id: formData.institute_branch_id,
        appointment_id: formData.appointment_id,
        norm: formData.norm,
        norm_mother: formData.norm_mother,
        idcard: formData.idcard,
        typeidcard: formData.typeidcard,
        alias: formData.alias,
        f_title: '',
        name: formData.name,
        b_title: '',
        fullname: formData.name,
        birth_date: formData.birth_date,
        birth_place: formData.birth_place,
        address: formData.address,
        sex: formData.sex,
        blood: formData.blood,
        married_id: formData.married_id,
        profession_id: formData.profession_id,
        citizenship_id: formData.citizenship_id,
        religion_id: formData.religion_id,
        last_edu_id: formData.last_edu_id,
        phone: formData.phone,
        email: formData.email,
        address_id: formData.address_id,
        zip_code: formData.zip_code,
        patient_issue_id: formData.patient_issue_id,
        patient_issue_text: formData.patient_issue_text,
        allergies: formData.allergyToInput,
        insurances: formData.insuranceToInput,
        responsible_id: formData.responsible_id,
        responsible_person_idcard: formData.responsible_person_idcard,
        responsible_person_typeidcard: formData.responsible_person_typeidcard,
        responsible_person_name: formData.responsible_person_name,
        responsible_person_phone: formData.responsible_person_phone,
        responsible_person_address_id: formData.responsible_person_address_id,
      }
      console.log('rawData: ', rawData)
      // const param = { rawData: rawData, url: 'xx', id: formData.id }
      const param = { rawData: rawData, url: 'mr/patient/create', id: '' }
      useIPA.postput(param)
        .then(response => {
          // router.push({ name: 'RegisMasterView' })
          if (response.data.hasErrors) {
            reject(response.data.message)
          } else {
            resolve(response.data)
          }
        },
          error => {
            if (error.response.data.hasOwnProperty('hasErrors')) {
              reject(error.response.data.message)
            } else {
              reject(error.message)
            }
          })
    })
  },
  // formPostPut ({ commit }, formData) {
  // 	return new Promise((resolve, reject) => {
  //     const rawData = new FormData()
  //     rawData.set('name', formData.text_1)
  //     rawData.set('desc', formData.text_2)
  //     if (formData.setImage === 'upload') {
  //       rawData.append('icon', formData.icon)
  // 		} else {
  //       rawData.set('icon', formData.icon)
  // 		}
  //     const param = { rawData: rawData, url: 'backoffice/master/bank', id: formData.id}
  //     useIPA.postput(param)
  // 		.then(response => {
  //       router.push({ name: 'app-bank' })
  //       if (response.data.hasErrors) {
  //         reject(response.data.message)
  //       } else {
  //         resolve(response.data)
  //       }
  //     },
  //     error => {
  //       if (error.response.data.hasOwnProperty('hasErrors')) {
  //         reject(error.response.data.message)
  //       } else {
  //         reject(error.message)
  //       }
  //     })
  // 	})
  // },
  remove({ commit }, id) {
    return new Promise((resolve, reject) => {
      const param = { url: 'backoffice/master/bank', id: id }
      useIPA.delete(param)
        .then(response => {
          if (response.data.hasErrors) {
            reject(response.data.message)
          } else {
            resolve(response.data)
          }
        },
          error => {
            if (error.response.data.hasOwnProperty('hasErrors')) {
              reject(error.response.data.message)
            } else {
              reject(error.message)
            }
          })
    })
  }
}

// mutations
const mutations = {
  setData(state, patient) {
    state.items = patient.rows
    state.totalRows = patient.rows.length
  },
  setListData(state, patient) {
    state.items = patient.rows.rows
    state.totalRows = patient.rows.iTotal
    state.start = patient.rows.start
  },
  setDataAllergy(state, allergy) {
    state.allergy.items = allergy.rows
    state.allergy.totalRows = allergy.rows.length
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
