import useIPA from '@/auth/jwt/useJwt'
import router from '@/router/index.js'

// initial state
const state = () => ({
  perPage: 20,
  pageOptions: [20, 50, 100],
  totalRows: 1,
  sortOptions: [],
  fields: [
    // { sortable: true, key: 'code', label: 'Kode', thStyle: { minWidth: '140px' } },
    // { sortable: true, key: 'register_date', label: 'Tanggal', thStyle: { minWidth: '140px' } },
    // { sortable: true, key: 'insurance_name', label: 'Cara Bayar', thStyle: { minWidth: '150px' } },
    // { sortable: true, key: 'type_how_to_enter_name', label: 'Cara Masuk', thStyle: { minWidth: '140px' } },
    // { sortable: true, key: 'exit_name', label: 'Status Pasien', thStyle: { minWidth: '140px' } },
    // { sortable: true, key: 'norm', label: 'No RM' },
    // { sortable: true, key: 'name', label: 'Nama', thStyle: { minWidth: '140px' } },
    // { sortable: true, key: 'is_new', label: 'Jenis Pasien', thStyle: { minWidth: '120px' } },
    // { sortable: true, key: 'birth_date', label: 'Tanggal Lahir', thStyle: { minWidth: '120px' } },
    // { sortable: true, key: 'age', label: 'Umur', thStyle: { minWidth: '140px' } },
    // { sortable: true, key: 'sex_name', label: 'Kelamin', thStyle: { minWidth: '140px' } },
    // { sortable: true, key: 'health_facility_name', label: 'Instalasi', thStyle: { minWidth: '140px' } },
    // { sortable: true, key: 'health_facility_item_name', label: 'Poli/Ruangan', thStyle: { minWidth: '140px' } },
    // { sortable: true, key: 'doctor_in_charge_name', label: 'Dokter', thStyle: { minWidth: '140px' } },
    // { sortable: true, key: 'queue', label: 'No Urut', thStyle: { minWidth: '80px' } },
    // { sortable: true, key: 'action', label: 'Aksi' },
    { key: 'code', label: 'Kode Pendaftaran' },
    { key: 'status', label: 'Status' },
    { key: 'register_date', label: 'Tanggal Pendaftaran' },
    { key: 'insurance_name', label: 'Cara Bayar' },
    { key: 'type_how_to_enter_name', label: 'Cara Masuk' },
    { key: 'norm', label: 'No RM' },
    { key: 'name', label: 'Nama Pasien [A/n]' },
    { key: 'birth_date', label: 'Tanggal Lahir' },
    { key: 'age', label: 'Umur' },
    { key: 'sex_name', label: 'Kelamin Pasien' },
    { key: 'health_facility_name', label: 'Instalasi Asal' },
    { key: 'health_facility_item_name', label: 'Poli/Ruangan Asal' },
    { key: 'doctor_in_charge_name', label: 'Dokter' },
    { key: 'action', label: 'Aksi' },
  ],
  items: [],
  invoice:{
    perPage: 20,
    pageOptions: [20, 50, 100],
    totalRows: 1,
    sortOptions: [],
    fields: [
      { key: 'no', label: 'No' },
      { key: 'var_3', label: 'Tagihan Pasien' },
      { key: 'var_2', label: 'Jumlah Terbayar' },
      // { key: 'remain', label: 'Sisa' },
      { key: 'date_added', label: 'Waktu' },
      { key: 'action_pay', label: 'Aksi' }
    ],
    items: []
  }
})

// getters
const getters = {}

// actions
const actions = {
  getDetail ({ commit }) {
    let param = {status: 'mr/registration/detail'}
    useIPA.get(param).then(response => {
      const patient = {
        rows : response.data.data
      }
      commit('setData', {...patient})
    })
  },
  getAll ({ commit }, params) {
    let queryParams = [];
  
    if (params.offset) {
      queryParams.push(`offset=${params.offset}`)
    } else if (params.offset === 0) {
      queryParams.push(`offset=${params.offset}`)
    }
  
    if (params.limit) {
      queryParams.push(`limit=${params.limit}`)
    }

    if (params.code) {
      queryParams.push(`code=${params.code}`)
    }

    if (params.norm) {
      queryParams.push(`norm=${params.norm}`)
    }

    if (params.name) {
      queryParams.push(`name=${params.name}`)
    }

    if (params.is_new) {
      if (params.is_new == 1) {
        queryParams.push(`is_new=N`)
      } else if (params.is_new == 2) {
        queryParams.push(`is_new=Y`)
      }
    }

    if (params.type_how_to_enter_id) {
      queryParams.push(`type_how_to_enter_id=${params.type_how_to_enter_id}`)
    }

    if (params.sex) {
      queryParams.push(`sex=${params.sex}`)
    }

    if (params.health_facility_id) {
      queryParams.push(`health_facility_id=${params.health_facility_id}`)
    }

    if (params.health_facility_item_id) {
      queryParams.push(`health_facility_item_id=${params.health_facility_item_id}`)
    }

    if (params.doctor_in_charge_id) {
      queryParams.push(`doctor_in_charge_id=${params.doctor_in_charge_id}`)
    }

    if (params.date_start && params.date_end) {
      queryParams.push(`date_start=${params.date_start}`)
      queryParams.push(`date_end=${params.date_end}`)
    }

    let paramsall = queryParams.join('&')
  
    let param = {status: `mr/registration/list?${paramsall}`}
    useIPA.get(param).then(response => {
      const registration = {
        rows : response.data.data
      }
      commit('setListData', {...registration})
    })
  },

  getByAllInvoice ({ commit }, id) {
    let param = {status: 'tr/transaction_invoice/detail?registration_id=' + id}
    useIPA.get(param).then(response => {
      const invoice = {
        rows : response.data.data
      }
      commit('setDataInvoice', {...invoice})
    },
    error => {
      if (error.response.data.hasOwnProperty('hasErrors')) {
        // commit('setDataInvoice', [])
        } else {
        // commit('setDataInvoice', [])
      }
    })
  },
  getByInvoiceId ({ commit }, id) {
		return new Promise((resolve, reject) => {
      let param = {status: 'tr/transaction_invoice/detail?id=' + id}
      useIPA.get(param).then(response => {
        resolve(response.data.data)
      })
    })
  },
  getById ({ commit }, id) {
		return new Promise((resolve, reject) => {
      let param = {status: 'mr/registration/detail?registration_destination_id=' + id}
      useIPA.get(param).then(response => {
          resolve(response.data.data)
      })
    })
  },
  formPostPutPayment ({ commit }, formData) {
    return new Promise((resolve, reject) => {
      console.log(formData)
      let url = 'finance/payment/create'
      if (formData.id) {
        url = 'finance/payment/update'
      }
      const param = { rawData: formData, url: url, id: formData.id }
      useIPA.postput(param)
			.then(response => {
        if (response.data.hasErrors) {
          reject(response.data.message)
        } else {
          resolve(response.data)
        }
      },
      error => {
        if (error.response.data.hasOwnProperty('hasErrors')) {
          reject(error.response.data.message)
        } else {
          reject(error.message)
        }
      })
		})
  },
  formPostPutInvoice ({ commit }, formData) {
		return new Promise((resolve, reject) => {
      const nurse = []
      const doctor = []
      const tool = []
      const blood = []
      const bhp = []
      let transactions
      for (var i = 0, l = formData.nurse.length; i < l; i++) {
        nurse[i] = {
          transaction_id: formData.nurse[i].id,
          flag_status_id: 1,
          type_transaction_id: 1
        }
      }
      for (var i = 0, l = formData.doctor.length; i < l; i++) {
        doctor[i] = {
          transaction_id: formData.doctor[i].id,
          flag_status_id: 1,
          type_transaction_id: 2
        }
      }
      for (var i = 0, l = formData.tool.length; i < l; i++) {
        tool[i] = {
          transaction_id: formData.tool[i].id,
          flag_status_id: 1,
          type_transaction_id: 5
        }
      }
      for (var i = 0, l = formData.blood.length; i < l; i++) {
        blood[i] = {
          transaction_id: formData.blood[i].id,
          flag_status_id: 1,
          type_transaction_id: 7
        }
      }
      for (var i = 0, l = formData.bhp.length; i < l; i++) {
        bhp[i] = {
          transaction_id: formData.bhp[i].id,
          flag_status_id: 1,
          type_transaction_id: 6
        }
      }
      transactions = {...nurse,...doctor,...tool,...blood,...bhp}
      const rawData = {
        registration_id: formData.registration_id,
        cashier_id: '3ada9806-8d98-4887-8bf8-c510483f454c',
        var_1: 0,
        var_2: 0,
        var_3: formData.amount_total,
        transactions
			}
      const param = { rawData: rawData, url: 'tr/transaction_invoice/create', id: '' }
      useIPA.postput(param)
			.then(response => {
        if (response.data.hasErrors) {
          reject(response.data.message)
        } else {
          resolve(response.data)
        }
      },
      error => {
        if (error.response.data.hasOwnProperty('hasErrors')) {
          reject(error.response.data.message)
        } else {
          reject(error.message)
        }
      })
		})
	},
  // formPostPut ({ commit }, formData) {
	// 	return new Promise((resolve, reject) => {
  //     const rawData = new FormData()
  //     rawData.set('name', formData.text_1)
  //     rawData.set('desc', formData.text_2)
  //     if (formData.setImage === 'upload') {
  //       rawData.append('icon', formData.icon)
	// 		} else {
  //       rawData.set('icon', formData.icon)
	// 		}
  //     const param = { rawData: rawData, url: 'backoffice/master/bank', id: formData.id}
  //     useIPA.postput(param)
	// 		.then(response => {
  //       router.push({ name: 'app-bank' })
  //       if (response.data.hasErrors) {
  //         reject(response.data.message)
  //       } else {
  //         resolve(response.data)
  //       }
  //     },
  //     error => {
  //       if (error.response.data.hasOwnProperty('hasErrors')) {
  //         reject(error.response.data.message)
  //       } else {
  //         reject(error.message)
  //       }
  //     })
	// 	})
	// },
  remove ({ commit }, id) {
		return new Promise((resolve, reject) => {
      const param = { url: 'backoffice/master/bank', id: id }
      useIPA.delete(param)
			.then(response => {
        if (response.data.hasErrors) {
          reject(response.data.message)
        } else {
          resolve(response.data)
        }
      },
      error => {
        if (error.response.data.hasOwnProperty('hasErrors')) {
          reject(error.response.data.message)
        } else {
          reject(error.message)
        }
      })
		})
	}
}

// mutations
const mutations = {
  setData (state, patient) {
    state.items = patient.rows
    state.totalRows = patient.rows.length
  },
  setListData (state, registration) {
    state.items = registration.rows.rows
    state.totalRows = registration.rows.iTotal
    state.start = registration.rows.start
  },
  setDataInvoice (state, invoice) {
    invoice.rows.forEach(function (element,index) {
      element.no = index+1
    })
    state.invoice.items = invoice.rows
    state.invoice.totalRows = invoice.rows.length
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
