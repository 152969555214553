import useIPA from '@/auth/jwt/useJwt'
import router from '@/router/index.js'

// initial state
const state = () => ({
  start: 20,
  currentPage: 1,
  totalRows: 1,
  pageOptions: [20, 50, 100],
  sortOptions: [],
  fields: [
    { sortable: true, key: 'code', label: 'No Pendaftaran', thStyle: { minWidth: '140px' } },
    { sortable: true, key: 'register_date', label: 'Tanggal', thStyle: { minWidth: '140px' } },
    { sortable: true, key: 'insurance_name', label: 'Cara Bayar', thStyle: { minWidth: '150px' } },
    { sortable: true, key: 'type_how_to_enter_name', label: 'Cara Masuk', thStyle: { minWidth: '140px' } },
    { sortable: true, key: 'exit_name', label: 'Status Pasien', thStyle: { minWidth: '140px' } },
    { sortable: true, key: 'norm', label: 'No RM' },
    { sortable: true, key: 'name', label: 'Nama', thStyle: { minWidth: '140px' } },
    { sortable: true, key: 'is_new', label: 'Jenis Pasien', thStyle: { minWidth: '120px' } },
    { sortable: true, key: 'birth_date', label: 'Tanggal Lahir', thStyle: { minWidth: '120px' } },
    { sortable: true, key: 'age', label: 'Umur', thStyle: { minWidth: '140px' } },
    { sortable: true, key: 'sex_name', label: 'Kelamin', thStyle: { minWidth: '140px' } },
    { sortable: true, key: 'health_facility_name', label: 'Instalasi', thStyle: { minWidth: '140px' } },
    { sortable: true, key: 'health_facility_item_name', label: 'Poli/Ruangan', thStyle: { minWidth: '140px' } },
    { sortable: true, key: 'doctor_in_charge_name', label: 'Dokter', thStyle: { minWidth: '140px' } },
    { sortable: true, key: 'queue', label: 'No Urut', thStyle: { minWidth: '80px' } },
    { sortable: true, key: 'action', label: 'Aksi' },
  ],
  items: []
})

// getters
const getters = {}

// actions
const actions = {
  getAll ({ commit }, params) {
    let queryParams = [];
  
    if (params.offset) {
      queryParams.push(`offset=${params.offset}`)
    } else if (params.offset === 0) {
      queryParams.push(`offset=${params.offset}`)
    }
  
    if (params.limit) {
      queryParams.push(`limit=${params.limit}`)
    }

    if (params.code) {
      queryParams.push(`code=${params.code}`)
    }

    if (params.norm) {
      queryParams.push(`norm=${params.norm}`)
    }

    if (params.name) {
      queryParams.push(`name=${params.name}`)
    }

    if (params.is_new) {
      if (params.is_new == 1) {
        queryParams.push(`is_new=N`)
      } else if (params.is_new == 2) {
        queryParams.push(`is_new=Y`)
      }
    }

    if (params.type_how_to_enter_id) {
      queryParams.push(`type_how_to_enter_id=${params.type_how_to_enter_id}`)
    }

    if (params.sex) {
      queryParams.push(`sex=${params.sex}`)
    }

    if (params.health_facility_id) {
      queryParams.push(`health_facility_id=${params.health_facility_id}`)
    }

    if (params.health_facility_item_id) {
      queryParams.push(`health_facility_item_id=${params.health_facility_item_id}`)
    }

    if (params.doctor_in_charge_id) {
      queryParams.push(`doctor_in_charge_id=${params.doctor_in_charge_id}`)
    }

    if (params.date_start && params.date_end) {
      queryParams.push(`date_start=${params.date_start}`)
      queryParams.push(`date_end=${params.date_end}`)
    }

    let paramsall = queryParams.join('&')
  
    let param = {status: `mr/registration/list?${paramsall}`}
    useIPA.get(param).then(response => {
      const registration = {
        rows : response.data.data
      }
      commit('setListData', {...registration})
    })
  },
  getDetail ({ commit }) {
    let param = {status: 'mr/registration/detail'}
    useIPA.get(param).then(response => {
      const registration = {
        rows : response.data.data
      }
      commit('setData', {...registration})
    })
  },
  getCancelledAll ({ commit }, params) {
    let queryParams = [];
  
    if (params.offset) {
      queryParams.push(`offset=${params.offset}`)
    } else if (params.offset === 0) {
      queryParams.push(`offset=${params.offset}`)
    }
  
    if (params.limit) {
      queryParams.push(`limit=${params.limit}`)
    }

    if (params.code) {
      queryParams.push(`code=${params.code}`)
    }

    if (params.norm) {
      queryParams.push(`norm=${params.norm}`)
    }

    if (params.name) {
      queryParams.push(`name=${params.name}`)
    }

    if (params.is_new) {
      if (params.is_new == 1) {
        queryParams.push(`is_new=N`)
      } else if (params.is_new == 2) {
        queryParams.push(`is_new=Y`)
      }
    }

    if (params.type_how_to_enter_id) {
      queryParams.push(`type_how_to_enter_id=${params.type_how_to_enter_id}`)
    }

    if (params.sex) {
      queryParams.push(`sex=${params.sex}`)
    }

    if (params.health_facility_id) {
      queryParams.push(`health_facility_id=${params.health_facility_id}`)
    }

    if (params.health_facility_item_id) {
      queryParams.push(`health_facility_item_id=${params.health_facility_item_id}`)
    }

    if (params.doctor_in_charge_id) {
      queryParams.push(`doctor_in_charge_id=${params.doctor_in_charge_id}`)
    }

    if (params.date_start && params.date_end) {
      queryParams.push(`date_start=${params.date_start}`)
      queryParams.push(`date_end=${params.date_end}`)
    }

    let paramsall = queryParams.join('&')
  
    let param = {status: `mr/registration/list_cancelled?${paramsall}`}
    useIPA.get(param).then(response => {
      const cancelled_reg = {
        rows : response.data.data
      }
      commit('setCancelListData', {...cancelled_reg})
    })
  },
  getById ({ commit }, id) {
		return new Promise((resolve, reject) => {
      let param = {status: 'mr/registration/detail?registration_destination_id=' + id}
      useIPA.get(param).then(response => {
          resolve(response.data.data)
      })
    })
  },
  getByRegistrationId ({ commit }, id) {
		return new Promise((resolve, reject) => {
      let param = {status: 'mr/registration/detail?registration_id=' + id}
      useIPA.get(param).then(response => {
          resolve(response.data.data)
      })
    })
  },
  getInpatientAll ({ commit }, params) {
    let queryParams = [];
  
    if (params.offset) {
      queryParams.push(`offset=${params.offset}`)
    } else if (params.offset === 0) {
      queryParams.push(`offset=${params.offset}`)
    }
  
    if (params.limit) {
      queryParams.push(`limit=${params.limit}`)
    }

    if (params.code) {
      queryParams.push(`code=${params.code}`)
    }

    if (params.norm) {
      queryParams.push(`norm=${params.norm}`)
    }

    if (params.name) {
      queryParams.push(`name=${params.name}`)
    }

    if (params.is_new) {
      if (params.is_new == 1) {
        queryParams.push(`is_new=N`)
      } else if (params.is_new == 2) {
        queryParams.push(`is_new=Y`)
      }
    }

    if (params.type_how_to_enter_id) {
      queryParams.push(`type_how_to_enter_id=${params.type_how_to_enter_id}`)
    }

    if (params.sex) {
      queryParams.push(`sex=${params.sex}`)
    }

    if (params.health_facility_id) {
      queryParams.push(`health_facility_id=${params.health_facility_id}`)
    }

    if (params.health_facility_item_id) {
      queryParams.push(`health_facility_item_id=${params.health_facility_item_id}`)
    }

    if (params.doctor_in_charge_id) {
      queryParams.push(`doctor_in_charge_id=${params.doctor_in_charge_id}`)
    }

    if (params.date_start && params.date_end) {
      queryParams.push(`date_start=${params.date_start}`)
      queryParams.push(`date_end=${params.date_end}`)
    }

    let paramsall = queryParams.join('&')
  
    let param = {status: `mr/registration/list?type_register_id=4&${paramsall}`}
    useIPA.get(param).then(response => {
      const inpatient = {
        rows : response.data.data
      }
      commit('setInpatientListData', {...inpatient})
    })
  },
  formPostPut ({ commit }, formData) {
		return new Promise((resolve, reject) => {
      const rawData = {
        norm: formData.norm,
        type_register_id: formData.type_register_id,
        patient: {
            idcard: formData.idcard,
            typeidcard: formData.typeidcard,
            alias: formData.alias,
            name: formData.name,
            birth_date: formData.birth_date,
            sex: formData.sex,
            phone: formData.phone,
            responsible_id: formData.responsible_id, 
            responsible_person_idcard: formData.responsible_person_idcard,
            responsible_person_typeidcard: formData.responsible_person_typeidcard,
            responsible_person_name: formData.responsible_person_name,
            responsible_person_phone: formData.responsible_person_phone,
        },
        how_to_enter_id: formData.how_to_enter_id.value,
        icd10_id: formData.icd10_id.value,
        destinations: [
          {
            // insurances: [
              //   {
            //     insurance_id: 1,
            //     company_id: '',
            //     service_class_id: '',
            //     number_insurance: '',
            //     expired: '',
            //   }
            // ],
            insurances: formData.insuranceToInput,
            health_facility_item_id: formData.health_facility_item_id.value,
            doctor_in_charge_id: formData.doctor_in_charge_id.value,
            bed_id: formData.bed_id, // belom ada
            type_emergency_id: formData.type_emergency_id.value, 
            type_emergency_case_id: formData.type_emergency_case_id.value,
            case_location: formData.case_location,
            name_location: formData.name_location,
            appointment_id: formData.appointment_id,
          }
        ]
			}
      // const param = { rawData: rawData, url: 'xx', id: formData.id }
      const param = { rawData: rawData, url: 'mr/registration/create', id: '' }
      useIPA.postput(param)
			.then(response => {
        router.push({ name: 'PatientListView' })
        if (response.data.hasErrors) {
          reject(response.data.message)
        } else {
          resolve(response.data)
        }
      },
      error => {
        if (error.response.data.hasOwnProperty('hasErrors')) {
          reject(error.response.data.message)
        } else {
          reject(error.message)
        }
      })
		})
	},
  remove ({ commit }, id) {
		return new Promise((resolve, reject) => {
      const param = { url: 'backoffice/master/bank', id: id }
      useIPA.delete(param)
			.then(response => {
        if (response.data.hasErrors) {
          reject(response.data.message)
        } else {
          resolve(response.data)
        }
      },
      error => {
        if (error.response.data.hasOwnProperty('hasErrors')) {
          reject(error.response.data.message)
        } else {
          reject(error.message)
        }
      })
		})
	},
  async updateIsCancelRegistration({ commit }, obj) {
    try {
      let rawData = {}
      if (obj.purpose == 'cancel') {
        rawData = {
          is_cancel: '1',
        }
      } else if (obj.purpose == 'restore') {
        rawData = {
          is_cancel: '0',
        }
      }
      const param = { rawData: rawData, url: 'mr/registration/cancel/', id: obj.registration_id };
      const response = await useIPA.postput(param);
      if (response.data.hasErrors) {
        throw new Error(response.data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  },
  formTransferPost ({ commit }, formData) {
		return new Promise((resolve, reject) => {
      const rawData = {
        registration_destination_past_id: formData.registration_destination_past_id,
        registration_id: formData.registration_id,
        health_facility_item_id: formData.health_facility_item_id.value,
        doctor_in_charge_id: formData.doctor_in_charge_id.value,
        bed_id: formData.bed_id,
        transfer_reason: formData.transfer_reason,
        insurances: formData.insuranceToInput,
			}
      const param = { rawData: rawData, url: 'mr/registration_destination/transfer', id: '' }
      useIPA.postput(param)
			.then(response => {
        // router.push({ name: 'PatientListView' })
        if (response.data.hasErrors) {
          reject(response.data.message)
        } else {
          resolve(response.data)
        }
      },
      error => {
        if (error.response.data.hasOwnProperty('hasErrors')) {
          reject(error.response.data.message)
        } else {
          reject(error.message)
        }
      })
		})
	},

}

// mutations
const mutations = {
  setData (state, registration) {
    state.items = registration.rows
    state.totalRows = registration.rows.length
  },
  setListData (state, registration) {
    state.items = registration.rows.rows
    state.totalRows = registration.rows.iTotal
    state.start = registration.rows.start
  },
  setCancelListData (state, cancelled_reg) {
    state.items = cancelled_reg.rows.rows
    state.totalRows = cancelled_reg.rows.iTotal
    state.start = cancelled_reg.rows.start
  },
  setInpatientListData (state, inpatient) {
    state.items = inpatient.rows.rows
    state.totalRows = inpatient.rows.iTotal
    state.start = inpatient.rows.start
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
