import Vue from 'vue'
import router from './router'
import store from './store'
import App from './App.vue'
import VueCompositionAPI from '@vue/composition-api'

// 3rd party plugins
import '@/libs/toastification'

// Vue-Barcode
import VueBarcode from '@chenfengyuan/vue-barcode';
const app = Vue;
app.component(VueBarcode.name, VueBarcode);

// Bootstrap Vue
import { BootstrapVue, ToastPlugin, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(ToastPlugin)
Vue.use(IconsPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// V-calendar
import Calendar from 'v-calendar'
import DatePicker from 'v-calendar'
Vue.component('calendar', Calendar)
Vue.component('date-picker', DatePicker)

// Global Components
import './global-components'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
