import useIPA from '@/auth/jwt/useJwt'
import router from '@/router/index.js'

// initial state
const state = () => ({
  perPage: 20,
  pageOptions: [20, 50, 100],
  totalRows: 1,
  firstRow: 1,
  lastRow: 1,
  totalRows: 1,
  sortOptions: [],
  fields: [
    { key: 'name', label: 'Ruangan', sortable: true },
    { key: 'type_room_name', label: 'Tipe Ruangan', sortable: true },
    { key: 'is_delete', label: 'Status', sortable: true },
    { key: 'action', label: 'Aksi'},
  ],
  items: [],
  items_type_room_monitoring: [],
  items_room_monitoring: [],
  items_bed: [],
  options:{
    room:[],
    bed:[],
  }
})

// getters
const getters = {}

// actions
const actions = {
  getParamRoom({ commit }, params) {
    return new Promise((resolve, reject) => {
      let param = {
        status: `health_facility/room/detail?type_room_id=${params}`
      }
      useIPA.get(param).then((response) => {
        const Param = {
          rows: response.data.data
        }
        commit('setParamRoom', { ...Param })
      })
    })
  },
  getParamBed({ commit }, params) {
    return new Promise((resolve, reject) => {
      let param = {
        status: `health_facility/bed/detail?type_status_bed_id=1&room_id=${params}`
      }
      useIPA.get(param).then((response) => {
        const Param = {
          rows: response.data.data
        }
        commit('setParamBed', { ...Param })
      })
    })
  },
  getAll ({ commit }) {
    let param = {status: 'health_facility/room/detail'}
    useIPA.get(param).then(response => {
      const parameter = {
        rows : response.data.data
      }
      commit('setData', {...parameter})
    })
  },
  getTypeRoomMonitoring ({ commit }) {
    let param = {status: 'health_facility/room/type_monitoring'}
    useIPA.get(param).then(response => {
      commit('setTypeRoomData', response.data.data)
    })
  },
  getRoomMonitoring ({ commit }, tyepe_room_id) {
    let param = {status: `health_facility/room/monitoring?type_room_id=${tyepe_room_id}`}
    useIPA.get(param).then(response => {
      commit('setRoomData', response.data.data)
    })
  },
  getBed ({ commit }, room_id) {
    let param = {status: `health_facility/bed/detail?room_id=${room_id}`}
    useIPA.get(param).then(response => {
      commit('setBedData', response.data.data)
    })
  },
  // getById ({ commit }, id) {
	// 	return new Promise((resolve, reject) => {
  //     let param = {status: 'master/bank/' + id}
  //     useIPA.get(param).then(response => {
  //         resolve(response.data.data)
  //     })
  //   })
  // },
  formPostPut ({ commit }, formData) {
		return new Promise((resolve, reject) => {
      let url = 'health_facility/room/create'
      if (formData.id) {
        url = 'health_facility/room/update'
      }
      const param = { rawData: formData, url: url, id: formData.id }
      useIPA.postput(param)
			.then(response => {
        if (response.data.hasErrors) {
          reject(response.data.message)
        } else {
          resolve(response.data)
        }
      },
      error => {
        if (error.response.data.hasOwnProperty('hasErrors')) {
          reject(error.response.data.message)
        } else {
          reject(error.message)
        }
      })
		})
	},
  remove ({ commit }, id) {
		return new Promise((resolve, reject) => {
      const rawData = {
        is_delete: 1,
			}
      const param = { rawData: rawData, url: 'health_facility/room/delete', id: id }
      useIPA.delete(param)
			.then(response => {
        if (response.data.hasErrors) {
          reject(response.data.message)
        } else {
          resolve(response.data)
        }
      },
      error => {
        if (error.response.data.hasOwnProperty('hasErrors')) {
          reject(error.response.data.message)
        } else {
          reject(error.message)
        }
      })
		})
	}
}

// mutations
const mutations = {
  setData (state, room) {
    state.items = room.rows
    state.totalRows = room.rows.length
    state.options.room = []
    for (let i = 0; i < room.rows.length; i++) {
      const option = []
      for (const key in room.rows[i]) {
        if (key === 'id') { option['value'] = room.rows[i][key] 
        } else if (key === 'name') { option['title'] = room.rows[i][key] 
        } else if (key === 'value') { option['val'] = room.rows[i]['value'] 
        } else if (key === 'unit') { option['unit'] = room.rows[i][key] 
        } else if (key === 'id') { option['oid'] = room.rows[i][key] }
      }
      state.options.room.push(Object.assign({}, option))
    }
  },
  setTypeRoomData (state, type_room) {
    state.items_type_room_monitoring = type_room
  },
  setRoomData (state, room) {
    state.items_room_monitoring = room
  },
  setBedData (state, bed) {
    state.items_bed = bed
  },
  setParamRoom (state, data) {
    state.options.room = []
    const option = []
    for (let i = 0; i < data.rows.length; i++) {
      for (const key in data.rows[i]) {
        if (key === 'id') { option['value'] = data.rows[i][key] } else if (key === 'name') { option['title'] = data.rows[i][key] } else if (key === 'id') { option['oid'] = data.rows[i][key] }
      }
      state.options.room.push(Object.assign({}, option))
    }
  },
  setParamBed (state, data) {
    state.options.bed = []
    const option = []
    for (let i = 0; i < data.rows.length; i++) {
      for (const key in data.rows[i]) {
        if (key === 'id') { option['value'] = data.rows[i][key] } else if (key === 'name') { option['title'] = data.rows[i][key] } else if (key === 'id') { option['oid'] = data.rows[i][key] }
      }
      state.options.bed.push(Object.assign({}, option))
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
