export default [
    // ------------------------ Planning transaction -------------------------
    {
        path: '/pharmacy/planning',
        name: 'PharmacyPlanningView',
        component: () => import('@/views/pages/pharmacy/planning/PlanningTransactionView.vue'),
        meta: {
            layout: 'full'
        }
    },
    {
        path: '/pharmacy/planning/procurement',
        name: 'PharmacyPlanningProcurementView',
        component: () => import('@/views/pages/pharmacy/planning/print/ProcurementView.vue'),
        meta: {
            layout: 'full'
        }
    },
    {
        path: '/pharmacy/planning/details',
        name: 'PharmacyPlanningDetailsView',
        component: () => import('@/views/pages/pharmacy/planning/PlanningDetailsView.vue'),
        meta: {
            layout: 'full'
        }
    },
    // ------------------------ Purchase Order -------------------------
    {
        path: '/pharmacy/purchase',
        name: 'PharmacyPurchaseView',
        component: () => import('@/views/pages/pharmacy/purchase/PurchaseOrderView.vue'),
        meta: {
            layout: 'full'
        }
    },
    {
        path: '/pharmacy/purchase/print',
        name: 'PharmacyPurchasePrintView',
        component: () => import('@/views/pages/pharmacy/purchase/print/PrintPurchaseView.vue'),
        meta: {
            layout: 'full'
        }
    },
    {
        path: '/pharmacy/purchase/details',
        name: 'PharmacyPurchaseDetailsView',
        component: () => import('@/views/pages/pharmacy/purchase/PurchaseDetailsView.vue'),
        meta: {
            layout: 'full'
        }
    },
    // ------------------------ Acceptance -------------------------
    {
        path: '/pharmacy/acceptance',
        name: 'PharmacyAcceptanceView',
        component: () => import('@/views/pages/pharmacy/acceptance/AcceptanceView.vue'),
        meta: {
            layout: 'full'
        }
    },
    // ------------------------ Card Stock -------------------------
    {
        path: '/pharmacy/stock',
        name: 'PharmacyStockView',
        component: () => import('@/views/pages/pharmacy/stock/CardStockView.vue'),
        meta: {
            layout: 'full'
        }
    },
    {
        path: '/pharmacy/stock/available',
        name: 'PharmacyStockAvailableView',
        component: () => import('@/views/pages/pharmacy/stock/print/AvailabilityCardView.vue'),
        meta: {
            layout: 'full'
        }
    },
    {
        path: '/pharmacy/stock/supplies',
        name: 'PharmacySuppliesView',
        component: () => import('@/views/pages/pharmacy/stock/print/SuppliesView.vue'),
        meta: {
            layout: 'full'
        }
    },
    // ------------------------ mutation of medical devices -------------------------
    {
        path: '/pharmacy/mutation',
        name: 'PharmacyMutationView',
        component: () => import('@/views/pages/pharmacy/mutation/MutationView.vue'),
        meta: {
            layout: 'full'
        }
    },
    // ------------------------ Recipe -------------------------
    {
        path: '/pharmacy/recipe/outpatient',
        name: 'PharmacyRecipeOutpatientView',
        component: () => import('@/views/pages/pharmacy/recipe/outpatient/OutpatientRecipeView.vue'),
        meta: {
            layout: 'full'
        }
    },
    // ------------------------ Warehouse Stock Reduction -------------------------
    {
        path: '/pharmacy/reduction',
        name: 'PharmacyReductionView',
        component: () => import('@/views/pages/pharmacy/reduction/ReductionStockView.vue'),
        meta: {
            layout: 'full'
        }
    },
    // ------------------------ Selling Price -------------------------
    {
        path: '/pharmacy/selling',
        name: 'PharmacySellingView',
        component: () => import('@/views/pages/pharmacy/selling/SellingPriceView.vue'),
        meta: {
            layout: 'full'
        }
    },
    // ------------------------ Selling Price Review -------------------------
    {
        path: '/pharmacy/review',
        name: 'PharmacySPReviewView',
        component: () => import('@/views/pages/pharmacy/SPReview/SPReviewView.vue'),
        meta: {
            layout: 'full'
        }
    },
    {
        path: '/pharmacy/review/update',
        name: 'PharmacySPReviewUpdateView',
        component: () => import('@/views/pages/pharmacy/SPReview/SPUpdateView.vue'),
        meta: {
            layout: 'full'
        }
    },
    {
        path: '/pharmacy/review/price-list',
        name: 'PharmacySPReviewPriceListView',
        component: () => import('@/views/pages/pharmacy/SPReview/print/PriceListView.vue'),
        meta: {
            layout: 'full'
        }
    },
    // ------------------------ Catalog -------------------------
    {
        path: '/pharmacy/catalog',
        name: 'PharmacyCatalogMasterView',
        component: () => import('@/views/pages/pharmacy/catalog/CatalogMasterView.vue'),
        meta: {
            layout: 'full'
        }
    },
    // ------------------------ Opname -------------------------
    {
        path: '/pharmacy/opname',
        name: 'PharmacyOpnameView',
        component: () => import('@/views/pages/pharmacy/opname/OpnameView.vue'),
        meta: {
            layout: 'full'
        }
    },
    {
        path: '/pharmacy/opname/print',
        name: 'PharmacyOpnamePrintView',
        component: () => import('@/views/pages/pharmacy/opname/print/OpnameStockView.vue'),
        meta: {
            layout: 'full'
        }
    },
    // ------------------------ Reports -------------------------
    {
        path: '/pharmacy/report',
        name: 'PharmacyReportView',
        component: () => import('@/views/pages/pharmacy/report/ReportView.vue'),
        meta: {
            layout: 'full'
        }
    },
    {
        path: '/pharmacy/report/acceptance',
        name: 'PharmacyReportAcceptanceView',
        component: () => import('@/views/pages/pharmacy/report/acceptance/AcceptanceReportView.vue'),
        meta: {
            layout: 'full'
        }
    },
    {
        path: '/pharmacy/report/acceptance/print',
        name: 'PharmacyReportAcceptancePrintView',
        component: () => import('@/views/pages/pharmacy/report/acceptance/print/AcceptancePrintView.vue'),
        meta: {
            layout: 'full'
        }
    },
    {
        path: '/pharmacy/report/sales',
        name: 'PharmacyReportSalesView',
        component: () => import('@/views/pages/pharmacy/report/sales/SalesReportView.vue'),
        meta: {
            layout: 'full'
        }
    },
]
